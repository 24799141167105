<template>
  <ul class="clebex-item-section pill">
    <li class="clebex-item-section-label-inner">
      <div class="clebex-label-content-wrapper">
        <div class="label">
          <span class="highlight">{{ getTitle(group) }}</span>
        </div>
      </div>
    </li>
    <li
      class="clebex-item-section-item"
      :class="{ 'full-right-underline': !selectMode }"
      v-for="(visit, index) in visits"
      :key="index"
    >
      <router-link
        :to="{
          name: routeEditVisitorEditVisit,
          params: { visitId: visit.id }
        }"
        class="clebex-item-content-wrapper"
        v-if="!selectMode"
      >
        <span class="label">
          <span class="text">
            <span class="highlight">{{
              `${visit.host.first_name} ${visit.host.last_name}, `
            }}</span>
            {{
              `${displayDate(visit.datetime_from)} ${displayTime(
                visit.datetime_from
              )} - ${displayTime(visit.datetime_to)}`
            }}

            <span class="inner-icons">
              <span
                v-if="
                  visit.visitor.license_plate &&
                    visit.visitor.license_plate.length
                "
                class="declarable-icon"
                ><icon icon="#cx-vis1-parking-12x12"
              /></span>
              <span v-if="visit.notes" class="declarable-icon"
                ><icon icon="#cx-vis1-warning-12x12" />
              </span>
              <span v-if="visit.visitor.disability" class="declarable-icon"
                ><icon icon="#cx-vis1-disability-12x12" />
              </span>
            </span>
          </span>
        </span>
        <span class="follow-up-icons">
          <span class="follow-up-icon-item">
            <icon
              icon="#cx-app1-arrow-right-12x12"
              width="12"
              height="12"
            ></icon>
          </span>
        </span>
      </router-link>
      <div class="clebex-item-content-wrapper" v-else>
        <div class="checkbox tiny alt block">
          <input
            type="radio"
            :id="`visit-id-${visit.id}`"
            name="user"
            :disabled="
              (visit.booking && Object.keys(visit.booking).length) ||
                group === L_VISITS_PAST
            "
            :checked="visit.id === selectedItem"
            :value="visit.id"
            @change="setSelectedItem(visit.id)"
          />
          <label :for="`visit-id-${visit.id}`">
            <svg-icon icon="checkmark-strait"></svg-icon>
            <span class="label">
              <span class="text">
                <span class="highlight">{{
                  `${visit.host.first_name} ${visit.host.last_name}, `
                }}</span>
                {{
                  `${displayDate(visit.datetime_from)} ${displayTime(
                    visit.datetime_from
                  )} - ${displayTime(visit.datetime_to)}`
                }}

                <span class="inner-icons">
                  <span
                    v-if="
                      visit.visitor.license_plate &&
                        visit.visitor.license_plate.length
                    "
                    class="declarable-icon"
                    ><icon icon="#cx-vis1-parking-12x12"
                  /></span>
                  <span v-if="visit.notes" class="declarable-icon"
                    ><icon icon="#cx-vis1-warning-12x12" />
                  </span>
                  <span v-if="visit.visitor.disability" class="declarable-icon"
                    ><icon icon="#cx-vis1-disability-12x12" />
                  </span>
                </span>
              </span>
            </span>
          </label>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { DateTime } from "luxon";
import { formatDate } from "@/services/helpers";
import { mapGetters } from "vuex";
import visitGroups from "@/services/consts/modules/visitor-management/visits";
import { L_VISITS_PAST } from "@/services/consts/modules/visitor-management/visits";

export default {
  name: "VisitsGroup",
  props: {
    selectedItem: {
      required: false,
      type: [Boolean, Number]
    },
    group: {
      required: true,
      type: String
    },
    setSelectedItem: {
      required: true,
      type: Function
    },
    visits: {
      required: true,
      type: Object
    },
    selectMode: {
      type: Boolean
    }
  },
  data() {
    return {
      visitGroups: visitGroups,
      L_VISITS_PAST: L_VISITS_PAST
    };
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    routeEditVisitorEditVisit() {
      if (this.$route.name.includes("my-visitors")) {
        return "r_my-visitors-reception-desk-edit-visitor-edit-visit";
      }
      return "r_reception-desk-edit-visitor-edit-visit";
    }
  },
  methods: {
    getTitle(group) {
      return this.displayLabelName(
        "visitor-management",
        "visit",
        this.visitGroups[group].label
      );
    },
    displayDate(date) {
      return formatDate(date, this.globalDateFormat);
    },
    displayTime(time) {
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    }
  }
};
</script>
